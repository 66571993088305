import { moduleActionContext } from '@/store'
import { AuthState, RootState } from '../types'
import { ActionContext } from 'vuex'
import firebase from 'firebase/app'

type AuthModuleContext = ActionContext<AuthState, RootState>;

const state = {
  loggedIn: false
} as AuthState

const getters = {}

const actions = {
  async subscribe(context: AuthModuleContext) {
    const { commit } = authModuleActionContext(context)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        commit.setLoginState(true)
      } else {
        commit.setLoginState(false)
      }
    })
  },
  async login(context: AuthModuleContext, credentials: { email: string, password: string }): Promise<boolean> {
    const { commit } = authModuleActionContext(context)
    try {
      await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
      commit.setLoginState(true)
      return true
    } catch(error) {
      return false
    }
  },
  async logout(context: AuthModuleContext) {
    const { commit } = authModuleActionContext(context)
    await firebase.auth().signOut()
    commit.setLoginState(false)
  }
}

const mutations = {
  setLoginState(state: AuthState, loggedIn: boolean) {
    state.loggedIn = loggedIn
  }
}

const authModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default authModule
export const authModuleActionContext = (context: AuthModuleContext) =>
  moduleActionContext(context, authModule)
