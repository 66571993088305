class GPSLocation {
    latitude: number = 0
    longitude: number = 0
    accuracy: number = 0

    constructor(data: any) {
      if (typeof data.latitude === 'number') this.latitude = data.latitude
      if (typeof data.longitude === 'number') this.longitude = data.longitude
      if (typeof data.accuracy === 'number') this.accuracy = data.accuracy
    }
}

export { GPSLocation }