import Vue from 'vue'
import firebase from 'firebase/app'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const ifAuthenticated = (_to: any, _from: any, next: any) => {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      next()
      return
    }
    next('/login')
  })
}

const routes = [
  {
    path: '/',
    name: 'Game',
    component: () => import(/* webpackChunkName: "game" */ '../views/Game')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '../views/History')
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import(/* webpackChunkName: "revew" */ '../views/Review')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/report/:id',
    name: 'Report',
    props: true,
    component: () => import(/* webpackChunkName: "report" */ '../views/Report')
  },
  {
    path: '/photo/:id',
    name: 'Photo',
    props: true,
    component: () => import(/* webpackChunkName: "report" */ '../views/Photo')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
