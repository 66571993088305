import Vue from 'vue'
import { moduleActionContext, storehelper } from '@/store'
import { SessionsState, RootState, FirestoreQuerySnapper, FirestoreSnapperType } from '../types'
import { KeyDict } from '@/types'
import { Session, GPSLocation } from '@/classes'
import { ActionContext } from 'vuex'
import { firestore } from 'firebase'
import axios from 'axios'
import db from '@/classes/firebase'

type SessionsModuleContext = ActionContext<SessionsState, RootState>;

const state = {
  sessions: {} as KeyDict<Session>,
  locations: {} as KeyDict<GPSLocation>
} as SessionsState

const getters = {
}

const actions = {
  async subscribe(context: SessionsModuleContext): Promise<{count: number, key: string}> {
    const { commit } = sessionModuleActionContext(context)
    const subscriptionKey = 'sessions'
    const snapper: FirestoreQuerySnapper = {
      query: db.collection('sessions'),
      mutation: commit.update,
      type: FirestoreSnapperType.Query
    }
    const subscriptionKeyLoc = 'locations'
    const yesterday = (new Date()).getTime() - 24 * 3600 * 1000
    const snapperLoc: FirestoreQuerySnapper = {
      query: db.collection('locations').where('gps.time', '>', yesterday),
      mutation: commit.updateLocation,
      type: FirestoreSnapperType.Query
    }
    await storehelper.subscribe(subscriptionKey, snapper)
    return await storehelper.subscribe(subscriptionKeyLoc, snapperLoc)
  },
  async unsubscribe (_context: SessionsModuleContext): Promise<{count: number, key: string} | undefined> {
    storehelper.unsubscribe('locations')
    return storehelper.unsubscribe('sessions')
  },
  async adjustWaypoint(context: SessionsModuleContext, payload: { pin: string, waypoint: number }): Promise<boolean> {
    const { state } = sessionModuleActionContext(context)
    if (payload.waypoint < 0) return false
    if (!state.sessions[payload.pin]) return false
    await db.collection('sessions').doc(payload.pin).set({waypoint: payload.waypoint}, {merge: true})
    return true
  },
  async delete(_context: SessionsModuleContext, pin: string): Promise<string> {
    await db.collection('sessions').doc(pin).delete()
    await db.collection('locations').doc(pin).delete()
    return pin
  },
  async update(_context: SessionsModuleContext, session: Partial<Session>) {
    if (session.pin) {
      await db.collection('sessions').doc(session.pin).set(session, { merge: true })
    }
  },
  async add(context: SessionsModuleContext, payload: { route: string, name: string }): Promise<string> {
    const { state } = sessionModuleActionContext(context)
    let pin: string = '' + (100000 + Math.floor((Math.random() * 900000)))
    while (state.sessions['' + pin]) {
      /* istanbul ignore next */
      pin = '' + (100000 + Math.floor((Math.random() * 900000)))
    }
    const created = (new Date()).getTime()
    await db.collection('sessions').doc(pin).set({ route: payload.route, name: payload.name,
                                                   waypoint: 0, state: 'open', created })
    await db.collection('locations').doc(pin).set({})
    return pin
  },
  async finish(_context: SessionsModuleContext, pin: string): Promise<string> {
    await axios.get(`https://us-central1-beatout-2738d.cloudfunctions.net/stopSession?pin=${pin}`)
    return pin
  }
}

const mutations = {
  update (state: SessionsState, snap: firestore.QuerySnapshot) {
    const foundKeys: string[] = []
    snap.forEach(session_doc => {
      const session = new Session({...session_doc.data(), pin: session_doc.id})
      Vue.set(state.sessions, session.pin, session)
      foundKeys.push(session_doc.id)
    })
    for (const key of Object.keys(state.sessions)) {
      if (!foundKeys.includes(key)) {
        Vue.delete(state.sessions, key)
      }
    }
  },
  updateLocation (state: SessionsState, snap: firestore.QuerySnapshot) {
    const foundKeys: string[] = []
    snap.forEach(location_doc => {
      if (location_doc.data().gps && state.sessions[location_doc.id]) {
        const location = new GPSLocation(location_doc.data().gps)
        Vue.set(state.locations, location_doc.id, location)
        foundKeys.push(location_doc.id)
      }
    })
    for (const key of Object.keys(state.locations)) {
      if (!foundKeys.includes(key)) {
        Vue.delete(state.locations, key)
      }
    }
  }
}

const sessionsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default sessionsModule
export const sessionModuleActionContext = (context: SessionsModuleContext) =>
  moduleActionContext(context, sessionsModule)
