import store, { moduleActionContext, storehelper } from '@/store'
import { ReportState, RootState, FirestoreDocSnapper, FirestoreSnapperType } from '../types'
import { Session } from '@/classes'
import { ActionContext } from 'vuex'
import { firestore } from 'firebase'
// import { getConfig } from '@/configs'
import db from '@/classes/firebase'

type ReportModuleContext = ActionContext<ReportState, RootState>;

const state = {
  report: undefined
} as ReportState

const getters = {
}

const actions = {
  async subscribe(context: ReportModuleContext, report: string): Promise<{count: number, key: string}> {
    const { commit } = reportModuleActionContext(context)
    const subscriptionKey = `report_${report}`
    const snapper: FirestoreDocSnapper = {
      doc: db.collection('reports').doc(report),
      mutation: commit.update,
      type: FirestoreSnapperType.Document
    }
    return await storehelper.subscribe(subscriptionKey, snapper)
  },
  async unsubscribe (context: ReportModuleContext, report: string): Promise<{count: number, key: string}|undefined> {
    const subscriptionKey = `report_${report}`
    const { commit } = reportModuleActionContext(context)
    const result = storehelper.unsubscribe(subscriptionKey)
    if (result?.count === 0) {
      commit.clear()
    }
    return result
  }
}

const mutations = {
  update (state: ReportState, snap: firestore.DocumentSnapshot) {
    const report = snap.data()
    if (report !== undefined) {
      if (state.report?.route) {
        store.dispatch.game.unsubscribe(state.report.route)
      }
      state.report = new Session(report)
      store.dispatch.game.subscribe(state.report.route)
    }
  },
  clear (state: ReportState) {
    state.report = undefined
  }
}

const reportModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default reportModule
export const reportModuleActionContext = (context: ReportModuleContext) =>
  moduleActionContext(context, reportModule)
