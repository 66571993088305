import { QuestionType, ExportableClass } from '@/types'

class Waypoint extends ExportableClass {
  id: string = ''
  name: string = ''
  answer: string = ''
  choices?: string[] = undefined
  question: string = ''
  latitude: number = 0
  longitude: number = 0
  type: QuestionType = QuestionType.NONE
  closeImage?: string = undefined
  backgroundImage?: string = undefined
  correctImage?: string = undefined
  closeAudio?: string = undefined
  wrongImage?: string = undefined
  url: string = ''
  hints: {
    text: string;
    timeout: number;
  }[] = [];

  constructor(data: any) {
    super()
    if (typeof data.name === 'string') this.name = data.name
    if (typeof data.id === 'string') this.id = data.id
    if (typeof data.answer === 'string') this.answer = data.answer
    if (typeof data.choices === 'object') this.choices = data.choices
    if (typeof data.question === 'string') this.question = data.question
    if (typeof data.type === 'string') this.type = data.type
    if (typeof data.url === 'string') this.url = data.url
    this.loadImages(data)
    this.loadGPS(data)
    if (typeof data.hints === 'object') {
      for (const hint of data.hints) {
        this.hints.push({
          text: hint.text ?? '',
          timeout: hint.timeout ?? 300
        })
      }
    }
  }
  loadImages(data: any) {
    if (typeof data.closeAudio === 'string') this.closeAudio = data.closeAudio
    if (typeof data.wrongImage === 'string') this.wrongImage = data.wrongImage
    if (typeof data.closeImage === 'string') this.closeImage = data.closeImage
    if (typeof data.backgroundImage === 'string') this.backgroundImage = data.backgroundImage
  }
  loadGPS(data: any) {
    if (typeof data.latitude === 'number') this.latitude = data.latitude
    if (typeof data.longitude === 'number') this.longitude = data.longitude
  }
}

export { Waypoint }