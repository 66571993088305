import Vue from 'vue'
import Vuex from 'vuex'
import gps from './modules/gps'
import logs from './modules/logs'
import game from './modules/game'
import session from './modules/session'
import sessions from './modules/sessions'
import routes from './modules/routes'
import waypoints from './modules/waypoints'
import photos from './modules/photos'
import reports from './modules/report'
import auth from './modules/auth'
import { createDirectStore } from 'direct-vuex'
import StoreHelper from './storehelper'

Vue.use(Vuex)

const debug = false

export const storeDoc = {
  modules: {
    gps,
    logs,
    game,
    session,
    sessions,
    routes,
    waypoints,
    reports,
    photos,
    auth
  },
  strict: debug
} as const

const { store, rootActionContext, moduleActionContext } = createDirectStore(storeDoc)

export type AppStore = typeof store;

declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}

store.dispatch.auth.subscribe()

export { rootActionContext, moduleActionContext }
export default store

const storehelper = new StoreHelper(store)
export { storehelper }
