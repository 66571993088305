import store, { moduleActionContext } from '@/store'
import { LogsState, RootState } from '../types'
import { ActionContext } from 'vuex'
import db from '@/classes/firebase'
import firebase from 'firebase/app'

type LogModuleContext = ActionContext<LogsState, RootState>;

const state = {
  lastGPSLogged: 0
} as LogsState

const getters = {}

const actions = {
  async log(context: LogModuleContext, message: string) {
    if (store.state.session.pin) {
      const time = (new Date()).getTime()
      db.collection('sessions').doc(store.state.session.pin).update({
        logs: firebase.firestore.FieldValue.arrayUnion({ message, time, level: 'info' })
      })
    }
  },
  async warn(context: LogModuleContext, message: string) {
    if (store.state.session.pin) {
      const time = (new Date()).getTime()
      db.collection('sessions').doc(store.state.session.pin).update({
        logs: firebase.firestore.FieldValue.arrayUnion({ message, time, level: 'warn' })
      })
    }
  },
  async error(context: LogModuleContext, message: string) {
    if (store.state.session.pin) {
      const time = (new Date()).getTime()
      db.collection('sessions').doc(store.state.session.pin).update({
        logs: firebase.firestore.FieldValue.arrayUnion({ message, time, level: 'error' })
      })
    }
  },
  async logGPS(context: LogModuleContext, position: {latitude: number, longitude: number, accuracy: number}) {
    const { commit } = logsModuleActionContext(context)
    if (store.state.session.pin) {
      const { state } = logsModuleActionContext(context)
      const time = (new Date()).getTime()
      if (time > state.lastGPSLogged + 30000) {
        db.collection('locations').doc(store.state.session.pin).update({
          gps: {
            latitude: position.latitude,
            longitude: position.longitude,
            accuracy: position.accuracy,
            time
          }
        })
        commit.updateLastLogged(undefined)
      }
    }
  }
}

const mutations = {
  updateLastLogged(state: LogsState, time: number | undefined) {
    if (time === undefined) {
      state.lastGPSLogged = (new Date()).getTime()
    } else {
      state.lastGPSLogged = time
    }
  }
}

const logsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default logsModule
export const logsModuleActionContext = (context: LogModuleContext) =>
  moduleActionContext(context, logsModule)
