import { Route, Waypoint, Session, GPSLocation } from '@/classes'
import { InGameState, KeyDict } from '@/types'
import { firestore } from 'firebase'

export interface GPSState {
  latitude: number;
  longitude: number;
  altitude: number;
  altitudeAccuracy: number;
  accuracy: number;
  heading: number;
  speed: number;
  gpsPermissions: boolean;
  cameraPermissions: boolean;  // TODO: needs to go to its own module
  active: boolean;
  target: {
    latitude: number,
    longitude: number,
    distance: number,
    direction: number
  }
}

export interface LogsState {
  lastGPSLogged: number
}

export interface GameState {
  game?: Route
  replay: boolean
}

export interface SessionState {
  waypoint: number
  game?: string
  link?: string
  valid: boolean
  pin: string
  startClicked: boolean,
  timeCloseEnough: number,
  timeInWaypoint: number,
  gamestate: InGameState,
  coins: number
}

export interface SessionsState {
  sessions: KeyDict<Session>,
  locations: KeyDict<GPSLocation>
}

export interface WaypointsState {
  waypoints: KeyDict<Waypoint>
}

export interface RoutesState {
  routes: KeyDict<Route>
}

export interface ReportState {
  report: Session | undefined
}

export interface AuthState {
  loggedIn: boolean
}

export interface PhotosState {
  images: string[]
}

export interface RootState {
  gps: GPSState;
  logs: LogsState;
  photos: PhotosState;
  game: GameState;
  session: SessionState;
  sessions: SessionsState;
  waypoints: WaypointsState;
  report: ReportState;
  auth: AuthState;
}

export type UnsubscribeFunction = () => void

export type DocMutation = (doc: firestore.DocumentSnapshot) => void
export type QueryMutation = (doc: firestore.QuerySnapshot) => void

export enum FirestoreSnapperType {
  Query = 'query',
  Document = 'document'
}

export interface FirestoreSnapper {
  readonly type: FirestoreSnapperType
}

export interface FirestoreDocSnapper extends FirestoreSnapper {
  doc: firestore.DocumentReference,
  mutation: DocMutation,
  readonly type: FirestoreSnapperType.Document
}

export interface FirestoreQuerySnapper extends FirestoreSnapper {
  query: firestore.Query,
  mutation: QueryMutation,
  readonly type: FirestoreSnapperType.Query
}

export interface Subscription {
  count: number,
  destroy: UnsubscribeFunction | null,
  snapper: FirestoreSnapper | null
}
