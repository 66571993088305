import { KeyDict, ExportableClass } from '@/types'

class WaypointResult {
  coins: number = 0
  images: {label: string, url: string}[] = []

  constructor (data: any) {
    if (typeof data.coins === 'number') this.coins = data.coins
    if (data.images) {
      Object.values(data.images as KeyDict<KeyDict<string>>).forEach((images) => {
        for (const [label, url] of Object.entries(images)) {
          this.images.push({ label, url})
        }
      })
    }
  }
}

class LogMessage {
  time: number = 0
  message: string = ''
  level: 'info' | 'warn' | 'error' = 'info'

  constructor(data: any) {
    if (typeof data.message === 'string') this.message = data.message
    if (typeof data.time === 'number') this.time = data.time
    if (typeof data.level === 'string') this.level = data.level
  }
}

export enum SessionState {
  OPEN = 'open',
  FINISHED = 'finished'
}

class Session extends ExportableClass {
  pin: string = ''
  name: string = ''
  route: string = ''
  waypoint: number = 0
  results: WaypointResult[] = []
  logs: LogMessage[] = []
  state: SessionState = SessionState.FINISHED
  link: string = ''
  closed: number = 0
  created: number = 0

  constructor(data: any) {
    super()
    if (typeof data.pin === 'string') this.pin = data.pin
    if (typeof data.route === 'string') this.route = data.route
    if (typeof data.name === 'string') this.name = data.name
    if (typeof data.waypoint === 'number') this.waypoint = data.waypoint
    if (typeof data.results === 'object') {
      for (const waypointid in data.results) {
        while (parseInt(waypointid) < this.results.length) {
          this.results.push(new WaypointResult({}))
        }
        this.results[parseInt(waypointid)] = new WaypointResult(data.results[waypointid])
      }
    }
    if (typeof data.logs === 'object') {
      for (const log of data.logs) {
        this.logs.push(new LogMessage(log))
      }
    }
    this.loadState(data)
  }

  loadState(data: any): void {
    if (typeof data.link === 'string') this.link = data.link
    if (typeof data.closed === 'number') this.closed = data.closed
    if (typeof data.created === 'number') this.created = data.created
    if (data.state) this.state = data.state
  }

  totalCoins(): number {
    let coins = 0
    this.results.forEach(result => {coins += result.coins})
    return coins
  }

  totalImages(): number {
    let images = 0
    this.results.forEach(result => {images += result.images.length})
    return images
  }
}

export { Session, LogMessage }
