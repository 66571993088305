export interface KeyDict<T> {
  [id: string]: T
}

export interface HistoryStep {
  timestamp: number;
  latitude: number;
  longitude: number;
  message: string;
  wp: number;
}

export enum InGameState {
  WALKING = 'walking',
  CLOSE = 'close',
  CORRECT = 'correct',
  WRONG = 'wrong',
  FINISHED = 'finished'
}

export enum QuestionType {
  NONE = 'none',
  CHECKPOINT = 'checkpoint',
  STRING = 'string',
  NUMBER = 'number',
  MULTIPLECHOICE = 'multiplechoice',
  CAMERA = 'camera',
  RUN = 'run',
  FINISH = 'finish',
  AUDIO = 'audio'
}

export class ExportableClass {
  toDict (): Object {
    return JSON.parse(JSON.stringify(this))
  }
}

export interface MapLocation {
  latitude: number
  longitude: number
  accuracy?: number
  name: string
  id: string
}

export interface SelectOption {
  value: string,
  text: string
}