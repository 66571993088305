import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyDBZwI6ZgFA-4umZcKAp47exuFdH-KI_lU',
  authDomain: 'beatout-2738d.firebaseapp.com',
  databaseURL: 'https://beatout-2738d.firebaseio.com',
  projectId: 'beatout-2738d',
  storageBucket: 'beatout-2738d.appspot.com',
  messagingSenderId: '406464996428',
  appId: '1:406464996428:web:f90a6cab6aaeadbe9340e9',
  measurementId: 'G-TKCTLPVR6E'
}

const app = firebase.initializeApp(firebaseConfig)
let db = firebase.firestore(app)
const storage = firebase.storage()
let cleanupConnection: () => Promise<void> = async () => {}
let clearDB: () => Promise<void> = async () => {}

if (process.env.NODE_ENV === 'test') {
  // console.warn('Running using the firestore emulator')
  const firebase = require('@firebase/testing')
  const projectId = 'beatout-test-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
  db = firebase.initializeAdminApp({ projectId }).firestore()
  cleanupConnection = async () => {
    await Promise.all(firebase.apps().map((app: any) => app.delete()))
  }
  clearDB = async () => {
    await firebase.clearFirestoreData({ projectId })
  }
}

export default db
export { storage, cleanupConnection, clearDB }
