import store, { moduleActionContext } from '@/store'
import { GPSState, RootState } from '../types'
import { headingDistanceTo } from 'geolocation-utils'
import { ActionContext } from 'vuex'

type GPSModuleContext = ActionContext<GPSState, RootState>

let watchGps = 0
let subscriptions = 0

export const updatePosition = (event: Position) => {
  const gpsstate: Partial<GPSState> = {
    latitude: event.coords.latitude,
    longitude: event.coords.longitude,
    accuracy: event.coords.accuracy,
    altitude: event.coords.altitude ?? undefined,
    altitudeAccuracy: event.coords.altitudeAccuracy ?? undefined,
    heading: event.coords.heading ?? undefined,
    speed: event.coords.speed ?? undefined,
  }
  store.dispatch.gps.set(gpsstate)
}

/* istanbul ignore next */
const geofailure = (error: any) => {
  console.error('GEO FAILURE', error)
  store.dispatch.logs.error('GPS Error ' + error)
  if (error.code === 1) {
    store.commit.gps.setGPSPermissions(false)
  }
}

const state = {
  latitude: 0,
  longitude: 0,
  altitude: 0,
  accuracy: 0,
  altitudeAccuracy: 0,
  heading: 0,
  speed: 0,
  active: false,
  gpsPermissions: true,
  cameraPermissions: false,
  target: {
    latitude: 0,
    longitude: 0,
    distance: 0,
    direction: 0
  }
} as GPSState

const getters = {}

const actions = {
  async restartGPS(_context: GPSModuleContext): Promise<boolean> {
    if (state.active) {
      watchGps = navigator.geolocation.watchPosition(
        updatePosition,
        geofailure,
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 20000
        }
      )
      store.dispatch.logs.warn('GPS Module restarted')
      return true
    }
    return false
  },
  async askCameraPermissions(context: GPSModuleContext) {
    const { commit } = gpsModuleActionContext(context)
    navigator.mediaDevices.getUserMedia({video: true}).then(stream => {
      stream.getTracks().forEach(track => {
        track.stop()
      })
      commit.setCameraPermissions(true)
    }).catch(() => {
      commit.setCameraPermissions(false)
    })
  },
  async subscribe(context: GPSModuleContext) {
    const { state, commit } = gpsModuleActionContext(context)
    if (!state.active && subscriptions === 0) {
      if (watchGps) {
        navigator.geolocation.clearWatch(watchGps)
      }
      watchGps = navigator.geolocation.watchPosition(
        updatePosition,
        geofailure,
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 20000
        }
      )
      commit.set({ active: true })
    }
    subscriptions++
  },
  async unsubscribe(context: GPSModuleContext) {
    const { state, commit } = gpsModuleActionContext(context)
    subscriptions--
    if (state.active && subscriptions === 0) {
      navigator.geolocation.clearWatch(watchGps)
      commit.set({ active: false })
    }
  },
  async set(context: GPSModuleContext, gpsstate: Partial<GPSState>) {
    const { commit } = gpsModuleActionContext(context)
    commit.set(gpsstate)
    if (gpsstate.latitude && gpsstate.longitude) {
      store.dispatch.logs.logGPS({
        latitude: gpsstate.latitude,
        longitude: gpsstate.longitude,
        accuracy: gpsstate.accuracy ?? 0
      })
    }
  },
  async setTarget(context: GPSModuleContext, location: {latitude: number, longitude: number}) {
    const { commit } = gpsModuleActionContext(context)
    commit.setTarget(location)
  }
}

const mutations = {
  setTarget(state: GPSState, location: {latitude: number, longitude: number}) {
    state.target.latitude = location.latitude
    state.target.longitude = location.longitude
    store.commit.gps.updateTarget()
  },
  set(state: GPSState, update: Partial<GPSState>) {
    if (update.latitude !== undefined) state.latitude = update.latitude
    if (update.longitude !== undefined) state.longitude = update.longitude
    if (update.altitude !== undefined) state.altitude = update.altitude
    if (update.accuracy !== undefined) state.accuracy = update.accuracy
    if (update.altitudeAccuracy !== undefined) state.altitudeAccuracy = update.altitudeAccuracy
    if (update.heading !== undefined) state.heading = update.heading
    if (update.speed !== undefined) state.speed = update.speed
    if (update.active !== undefined) state.active = update.active
    store.commit.gps.updateTarget()
  },
  updateTarget(state: GPSState) {
    const heading = headingDistanceTo(
      { lat: state.latitude, lon: state.longitude },
      { lat: state.target.latitude, lon: state.target.longitude }
    )
    state.target.direction = heading.heading
    state.target.distance = Math.floor(heading.distance)
  },
  setGPSPermissions(state: GPSState, permissions: boolean) {
    state.gpsPermissions = permissions
  },
  setCameraPermissions(state: GPSState, permissions: boolean) {
    state.cameraPermissions = permissions
  }
}

const gpsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default gpsModule
export const gpsModuleActionContext = (context: GPSModuleContext) =>
  moduleActionContext(context, gpsModule)
