process.env.FIRESTORE_EMULATOR_HOST = 'localhost:9888'

import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.devtools = true
Vue.config.productionTip = false
