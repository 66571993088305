import store, { moduleActionContext, storehelper } from '@/store'
import { GameState, RootState, FirestoreDocSnapper, FirestoreSnapperType } from '../types'
import { Route, Waypoint } from '@/classes'
import { InGameState } from '@/types'
import { ActionContext } from 'vuex'
import { firestore } from 'firebase'
const accents = require('remove-accents')
import db from '@/classes/firebase'

type GameModuleContext = ActionContext<GameState, RootState>;

const cleanAnswer = (answer: string): string => {
  let parsedAnswer = answer.toLowerCase()
  parsedAnswer = parsedAnswer.trim()
  parsedAnswer = accents.remove(parsedAnswer)
  return parsedAnswer
}

const state = {
  game: undefined,
  replay: false
} as GameState

const getters = {
  currentRiddle(state: GameState): Waypoint | undefined {
    const waypoint = store.state.session.waypoint
    if (!state.game) {
      return undefined
    }
    if (waypoint >= 0 && waypoint < state.game.riddles.length) {
      return state.game.riddles[waypoint]
    }
    return undefined
  }
}

const actions = {
  async subscribe(context: GameModuleContext, id: string): Promise<{count: number, key: string}> {
    const { commit } = gameModuleActionContext(context)
    const subscriptionKey = `game_${id}`
    const snapper: FirestoreDocSnapper = {
      doc: db.collection('routes').doc(id),
      mutation: commit.updateRoute,
      type: FirestoreSnapperType.Document
    }
    return await storehelper.subscribe(subscriptionKey, snapper)
  },
  async unsubscribe(context: GameModuleContext, id: string): Promise<{count: number, key: string} | undefined> {
    return storehelper.unsubscribe(`game_${id}`)
  },
  async subscribeWaypoint(context: GameModuleContext, id: string): Promise<{count: number, key: string}> {
    const { commit } = gameModuleActionContext(context)
    const subscriptionKey = `waypoint_${id}`
    const snapper: FirestoreDocSnapper = {
      doc: db.collection('waypoints').doc(id),
      mutation: commit.updateWaypoint,
      type: FirestoreSnapperType.Document
    }
    return await storehelper.subscribe(subscriptionKey, snapper)
  },
  async unsubscribeWaypoint(context: GameModuleContext, id: string) {
    storehelper.unsubscribe(`waypoint_${id}`)
  },
  async setReplay(context: GameModuleContext, replay: boolean) {
    const { commit } = gameModuleActionContext(context)
    commit.setReplay(replay)
  },
  async goToNextPoint(_context: GameModuleContext) {
    store.commit.session.setInGameState(InGameState.WALKING)
    store.dispatch.session.increaseWaypoint()
  },
  async answer(context: GameModuleContext, answer: string): Promise<boolean> {
    console.log(`answer ${answer}`)
    const { state, getters } = gameModuleActionContext(context)
    if (!state.game || !getters.currentRiddle) {
      throw new Error('No game loaded')
    }
    const currentWaypoint = store.state.session.waypoint
    if (cleanAnswer(getters.currentRiddle.answer) === cleanAnswer(answer)) {
      store.commit.session.setInGameState(InGameState.CORRECT)
      setTimeout(() => {
        store.dispatch.session.goToWaypoint(currentWaypoint + 1)
      }, 10000)
      return true
    } else {
      store.commit.session.setInGameState(InGameState.WRONG)
      setTimeout(() => {
        store.commit.session.setInGameState(InGameState.WALKING)
      }, 5000)
      return false
    }
  }
}

const mutations = {
  setReplay (state: GameState, replay: boolean) {
    state.replay = replay
  },
  updateRoute (state: GameState, doc: firestore.DocumentSnapshot) {
    const route = doc.data()
    if (route !== undefined) {
      if (state.game !== undefined) {
        for (const waypoint of state.game.waypoints) {
          store.dispatch.game.unsubscribeWaypoint(waypoint)
        }
      }
      state.game = new Route(route)
      for (const waypoint of state.game.waypoints) {
        store.dispatch.game.subscribeWaypoint(waypoint)
      }
    }
  },
  updateWaypoint (state: GameState, doc: firestore.DocumentSnapshot) {
    if (state.game === undefined) return
    if (doc.data() === undefined) return
    state.game.updateWaypoint(doc.id, doc.data())
    const currentWaypoint = store.state.session.waypoint
    if (state.game?.riddles &&
        state.game.riddles[currentWaypoint] !== undefined &&
        doc.id === state.game.riddles[currentWaypoint].id) {
      const start = state.game.riddles[currentWaypoint]
      store.dispatch.gps.setTarget({ latitude: start.latitude, longitude: start.longitude })
    }
  }
}

const gameModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default gameModule
export const gameModuleActionContext = (context: GameModuleContext) =>
  moduleActionContext(context, gameModule)
