import Vue from 'vue'
import { moduleActionContext, storehelper } from '@/store'
import { WaypointsState, RootState, FirestoreQuerySnapper, FirestoreSnapperType } from '../types'
import { KeyDict } from '@/types'
import { Waypoint } from '@/classes'
import { ActionContext } from 'vuex'
import { firestore } from 'firebase'
import db from '@/classes/firebase'

type WaypointsModuleContext = ActionContext<WaypointsState, RootState>;

const state = {
  waypoints: {} as KeyDict<Waypoint>
} as WaypointsState

const getters = {
}

const actions = {
  async subscribe(context: WaypointsModuleContext): Promise<{count: number, key: string}> {
    const { commit } = sessionModuleActionContext(context)
    const subscriptionKey = 'waypoints'
    const snapper: FirestoreQuerySnapper = {
      query: db.collection('waypoints'),
      mutation: commit.update,
      type: FirestoreSnapperType.Query
    }
    return await storehelper.subscribe(subscriptionKey, snapper)
  },
  unsubscribe (_context: WaypointsModuleContext): {count: number, key: string} | undefined {
    const subscriptionKey = 'waypoints'
    return storehelper.unsubscribe(subscriptionKey)
  },
  add(_context: WaypointsModuleContext, waypoint: Waypoint) {
    db.collection('waypoints').doc(waypoint.id).set(waypoint.toDict())
  },
  delete(_context: WaypointsModuleContext, id: string) {
    db.collection('waypoints').doc(id).delete()
  }
}

const mutations = {
  update (state: WaypointsState, snap: firestore.QuerySnapshot) {
    const foundKeys: string[] = []
    snap.forEach(waypoint_doc => {
      const waypoint = new Waypoint({...waypoint_doc.data(), id: waypoint_doc.id, })
      Vue.set(state.waypoints, waypoint.id, waypoint)
      foundKeys.push(waypoint_doc.id)
    })
    for (const key of Object.keys(state.waypoints)) {
      if (!foundKeys.includes(key)) {
        Vue.delete(state.waypoints, key)
      }
    }
  }
}

const waypointsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default waypointsModule
export const sessionModuleActionContext = (context: WaypointsModuleContext) =>
  moduleActionContext(context, waypointsModule)
