import Vue from 'vue'
import { moduleActionContext, storehelper } from '@/store'
import { RoutesState, RootState, FirestoreQuerySnapper, FirestoreSnapperType } from '../types'
import { KeyDict } from '@/types'
import { Route } from '@/classes'
import { ActionContext } from 'vuex'
import { firestore } from 'firebase'
import db from '@/classes/firebase'

type RoutesModuleContext = ActionContext<RoutesState, RootState>;

const state = {
  routes: {} as KeyDict<Route>
} as RoutesState

const getters = {
}

const actions = {
  async subscribe(context: RoutesModuleContext): Promise<{count: number, key: string}> {
    const { commit } = routesModuleActionContext(context)
    const subscriptionKey = 'routes'
    const snapper: FirestoreQuerySnapper = {
      query: db.collection('routes'),
      mutation: commit.update,
      type: FirestoreSnapperType.Query
    }
    return await storehelper.subscribe(subscriptionKey, snapper)
  },
  async unsubscribe (_context: RoutesModuleContext): Promise<{count: number, key: string} | undefined> {
    const subscriptionKey = 'routes'
    return storehelper.unsubscribe(subscriptionKey)
  },
  async delete(_context: RoutesModuleContext, pin: string) {
    await db.collection('routes').doc(pin).delete()
  },
  async setWaypoints(_context: RoutesModuleContext, payload: {id: string, waypoints: string[]}) {
    await db.collection('routes').doc(payload.id).set({ waypoints: payload.waypoints }, {merge: true})
  }
  /*,
  async add(context: RoutesModuleContext, route: string): Promise<string> {
    const { state } = routesModuleActionContext(context)
    let pin: string = '' + Math.floor((Math.random() * 1000000))
    while (state.sessions['' + pin]) {
      pin = '' + Math.floor((Math.random() * 1000000))
    }
    await db.collection('sessions').doc(pin).set({ route, waypoint: 0 })
    return pin
  }*/
}

const mutations = {
  update (state: RoutesState, snap: firestore.QuerySnapshot) {
    const foundKeys: string[] = []
    snap.forEach(route_doc => {
      const route = new Route(route_doc.data())
      Vue.set(state.routes, route_doc.id, route)
      foundKeys.push(route_doc.id)
    })
    for (const key of Object.keys(state.routes)) {
      if (!foundKeys.includes(key)) {
        Vue.delete(state.routes, key)
      }
    }
  }
}

const routesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default routesModule
export const routesModuleActionContext = (context: RoutesModuleContext) =>
  moduleActionContext(context, routesModule)
