import { Subscription, FirestoreSnapper, FirestoreSnapperType,
         FirestoreQuerySnapper, FirestoreDocSnapper } from './types'
import { AppStore } from '.'
import Vue from 'vue'

class StoreHelper {
  store: AppStore
  subscriptions: {[key: string]: Subscription}
  constructor (store: AppStore) {
    this.store = store
    this.subscriptions = {}
  }
  async subscribe (key: string, snapper: FirestoreSnapper) {
    if (this.subscriptions[key] === undefined) {
      this.subscriptions[key] = {
        count: 0,
        destroy: null,
        snapper: null
      }
    }
    if (this.subscriptions[key].count === 0) {
      if (snapper.type === FirestoreSnapperType.Query) {
        const snap = snapper as FirestoreQuerySnapper
        this.subscriptions[key].destroy = snap.query.onSnapshot(data => {
          snap.mutation(data)
        }, error => {
          throw new Error(`Error subscribing ${key} : ${error}`)
        })
      } else if (snapper.type === FirestoreSnapperType.Document) {
        const snap = snapper as FirestoreDocSnapper
        const doc = await snap.doc.get()
        if (!doc.exists) {
          throw new Error(`${key}: document does not exists`)
        }
        this.subscriptions[key].destroy = snap.doc.onSnapshot(data => {
          snap.mutation(data)
        }, error => {
          throw new Error(`Error subscribing ${key} : ${error}`)
        })
      }
      this.subscriptions[key].snapper = snapper
    }
    this.subscriptions[key].count += 1
    return { key, count: this.subscriptions[key].count }
  }
  unsubscribe (key: string, mutation?: () => void) {
    if (this.subscriptions[key] === undefined) {
      // throw Error(`You should not unsubscribe if you have not subscribed yet ${key}`)
      console.warn(`You should not unsubscribe if you have not subscribed yet ${key}`)
      return
    } else if (this.subscriptions[key].count === 0) {
      // throw Error(`You should not unsubscribe if you have not subscribed yet ${key}`)
      console.warn(`You should not unsubscribe if you have not subscribed yet ${key}`)
      return
    } else {
      this.subscriptions[key].count -= 1
      const count = this.subscriptions[key].count
      const destroyFunction = this.subscriptions[key].destroy
      if (count === 0 && destroyFunction !== null) {
        destroyFunction()
        if (mutation) {
          mutation()
        }
        delete this.subscriptions[key]
      }
      return { key, count }
    }
  }
  exists (key: string): boolean {
    return this.subscriptions[key] && this.subscriptions[key].count > 0
  }
  runningKeys (prefix: string = '') {
    return Object.keys(this.subscriptions).filter(key => key.startsWith(prefix))
  }
  lazyUpdate (state: any, key: string, newVal: any) {
    if (typeof newVal === 'object') {
      for (const rightkey in newVal) {
        if (state[key][rightkey] === undefined) {
          Vue.set(state[key], rightkey, newVal[rightkey])
        } else {
          this.lazyUpdate(state[key], rightkey, newVal[rightkey])
        }
      }
      for (const leftkey in state[key]) {
        if (newVal[leftkey] === undefined) {
          Vue.delete(state[key], leftkey)
        }
      }
    } else if (state[key] !== newVal) {
      Vue.set(state, key, newVal)
    }
  }
}

export default StoreHelper
