import { moduleActionContext } from '@/store'
import { PhotosState, RootState } from '../types'
import { ActionContext } from 'vuex'
import db from '@/classes/firebase'

type PhotosModuleContext = ActionContext<PhotosState, RootState>;

const state = {
  images: []
} as PhotosState

const getters = {
}

const actions = {
  async get(context: PhotosModuleContext, id: string): Promise<string> {
    const { commit } = photosModuleActionContext(context)
    const doc = await db.collection('photos').doc(id).get()
    if (doc.exists) {
      const data = doc.data()
      if (data) {
        const url = data.url
        commit.add(url)
        return url
      }
    }
    throw new Error('Image not found')
  },
  async createId(_context: PhotosModuleContext, url: string): Promise<string> {
    const doc = await db.collection('photos').add({ url })
    return doc.id
  }
}

const mutations = {
  add (state: PhotosState, url: string) {
    state.images.push(url)
  }
}

const photosModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default photosModule
export const photosModuleActionContext = (context: PhotosModuleContext) =>
  moduleActionContext(context, photosModule)
