import store, { moduleActionContext, storehelper } from '@/store'
import { SessionState, RootState, FirestoreDocSnapper, FirestoreSnapperType } from '../types'
import { InGameState } from '@/types'
import { ActionContext } from 'vuex'
import { firestore } from 'firebase'
import axios from 'axios'
import db from '@/classes/firebase'
import Cookies from 'js-cookie'

type SessionModuleContext = ActionContext<SessionState, RootState>;

let intervalClose: NodeJS.Timer | null = null

/* This is a current quick fix for iOS that sometimes gets higher accuracy after some time -> needs to find better fix*/
let lastGPSCheck = 0
function checkGPS() {
  const now = (new Date()).getTime()
  if (lastGPSCheck < now - 5000) {
    if (store.state.gps.active && store.state.gps.accuracy > 50) {
      store.dispatch.gps.restartGPS()
      lastGPSCheck = now
    }
  }
}

const state = {
  valid: false,
  waypoint: 0,
  pin: '',
  game: undefined,
  startClicked: false,
  timeCloseEnough: 0,
  timeInWaypoint: 0,
  gamestate: InGameState.WALKING,
  coins: 0,
} as SessionState

const getters = {
}

const actions = {
  async subscribe(context: SessionModuleContext, session: string): Promise<{count: number, key: string}> {
    const { commit } = sessionModuleActionContext(context)
    const subscriptionKey = `session_${session}`
    const snapper: FirestoreDocSnapper = {
      doc: db.collection('sessions').doc(session),
      mutation: commit.update,
      type: FirestoreSnapperType.Document
    }
    intervalClose = setInterval(() => {
      commit.updateCloseEnough()
      checkGPS()
    }, 1000)
    return await storehelper.subscribe(subscriptionKey, snapper)
  },
  async unsubscribe (context: SessionModuleContext, session: string): Promise<{count: number, key: string}|undefined> {
    const subscriptionKey = `session_${session}`
    const { commit } = sessionModuleActionContext(context)
    const result = storehelper.unsubscribe(subscriptionKey)
    if (result?.count === 0) {
      commit.clear()
      if (intervalClose) {
        clearInterval(intervalClose)
      }
    }
    return result
  },
  async pinFromCookies(_context: SessionModuleContext) {
    const pin: string | undefined = Cookies.get('pin')
    return pin
  },
  async savePinToCookies(_context: SessionModuleContext, pin: string) {
    Cookies.set('pin', pin)
  },
  async clearPinCookies(_context: SessionModuleContext) {
    Cookies.remove('pin')
  },
  async clickStart(context: SessionModuleContext) {
    const { commit } = sessionModuleActionContext(context)
    commit.updateStartClicked(true)
  },
  async increaseWaypoint(context: SessionModuleContext) {
    const { state } = sessionModuleActionContext(context)
    db.collection('sessions').doc(state.pin).set({ waypoint: state.waypoint + 1 }, { merge: true }) // Todo use increase
  },
  async goToWaypoint(context: SessionModuleContext, waypoint: number) {
    db.collection('sessions').doc(state.pin).set({ waypoint }, { merge: true })
  },
  async addCoints(context: SessionModuleContext, coins: number) {
    db.collection('sessions').doc(state.pin).set({
      results: {
        [`${state.waypoint}`]: { coins }
      }
    }, { merge: true })
  },
  async addImage(context: SessionModuleContext, payload: { id: string, image: string, name: string }) {
    const { state } = sessionModuleActionContext(context)
    db.collection('sessions').doc(state.pin).set({
      results: {
        [`${state.waypoint}`]: {
          images: { [payload.id]: { [payload.name]: payload.image} }
        }
      }
    }, { merge: true })
  },
  async finish(context: SessionModuleContext) {
    const { state } = sessionModuleActionContext(context)
    await axios.get(`https://us-central1-beatout-2738d.cloudfunctions.net/stopSession?pin=${state.pin}`)
  }
}

const mutations = {
  update (state: SessionState, snap: firestore.DocumentSnapshot) {
    const session = snap.data()
    state.valid = session !== undefined
    if (session?.waypoint && state.waypoint !== session.waypoint) {
      store.commit.session.setInGameState(InGameState.WALKING)
      state.waypoint = session.waypoint
      state.timeInWaypoint = 0
      const riddle = store.state.game.game?.riddles[state.waypoint]
      if (riddle) {
        store.dispatch.gps.setTarget({latitude: riddle.latitude, longitude: riddle.longitude})
      }
    }
    if (session?.route) {
      const oldRoute = state.game
      state.game = session.route
      if (!store.state.game.game) {
        store.dispatch.game.subscribe(session.route)
      } else if (oldRoute !== session.route) {
        if (oldRoute) {
          store.dispatch.game.unsubscribe(oldRoute)
        }
        store.dispatch.game.subscribe(session.route)
      }
    }
    if (session?.results) {
      let count = 0
      Object.values(session.results).forEach((waypoint: any) => {
        if (typeof waypoint.coins === 'number') count += waypoint.coins
      })
      state.coins = count
    }
    state.link = session?.link
    state.pin = snap.id
  },
  clear (state: SessionState) {
    state.pin = ''
    state.valid = false
    state.waypoint = 0
    state.game = undefined
  },
  updateStartClicked (state: SessionState, clicked: boolean) {
    state.startClicked = clicked
  },
  setInGameState(state: SessionState, ingamestate: InGameState) {
    state.gamestate = ingamestate
  },
  updateCloseEnough (state: SessionState) {
    let minDistance = store.state.game.game?.minDistance ?? 50
    let minAccuracy = 30

    if (store.state.game.replay && state.timeInWaypoint > 10) {
      minDistance = 10000000000
      minAccuracy = 1000
    }
    if (
      store.state.gps.gpsPermissions &&
      store.state.gps.accuracy < minAccuracy &&
      store.state.gps.target.distance < minDistance &&
      state.gamestate === InGameState.WALKING
    ) {
      state.gamestate = InGameState.CLOSE
      store.dispatch.logs.log(`Arrived at waypoint ${state.waypoint}`)
      new Audio(require('@/assets/sound/arrived.mp3')).play()
      try {
        navigator.vibrate([500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500])
      } catch (error) {
        console.error(error)
      }
    }
    if (state.gamestate === InGameState.CLOSE) {
      state.timeCloseEnough++
    }
    state.timeInWaypoint++
  }
}

const sessionModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as const

export default sessionModule
export const sessionModuleActionContext = (context: SessionModuleContext) =>
  moduleActionContext(context, sessionModule)
