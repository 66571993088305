import { Waypoint } from './waypoint'
import { ExportableClass } from '@/types'
import Vue from 'vue'

class Route extends ExportableClass {
  name: string = ''
  minDistance: number = 30
  startPopup?: string 
  introAudio?: string
  backgroundImage?: string
  reportTitle?: string
  reportText?: string
  shareTitle?: string
  shareText?: string
  waypoints: string[] = []
  riddles: Waypoint[] = []

  /* eslint-disable complexity */
  constructor(data: any) {
    super()
    if (typeof data.name === 'string') this.name = data.name
    if (typeof data.minDistance === 'number') this.minDistance = data.minDistance
    if (typeof data.startPopup === 'string') this.startPopup = data.startPop
    if (typeof data.introAudio === 'string') this.introAudio = data.introAudio
    if (typeof data.backgroundImage === 'string') this.backgroundImage = data.backgroundImage
    if (typeof data.reportTitle === 'string') this.reportTitle = data.reportTitle
    if (typeof data.reportText === 'string') this.reportText = data.reportText
    if (typeof data.shareTitle === 'string') this.shareTitle = data.shareTitle
    if (typeof data.shareText === 'string') this.shareText = data.shareText
    if (typeof data.riddles === 'object') {
      for (const [id, riddle] of Object.entries(data.riddles)) {
        this.riddles.push(new Waypoint({...riddle as object, id}))
      }
    }
    if (typeof data.waypoints === 'object') {
      for (const id of data.waypoints) {
        this.waypoints.push(id)
      }
    }
  }

  updateWaypoint(id: string, data: any): boolean {
    const index = this.waypoints.indexOf(id)
    if (index < 0) return false
    while (index < this.riddles.length-1) {
      this.riddles.push(new Waypoint({}))
    }
    Vue.set(this.riddles, index, new Waypoint({ ...data, id}))
    return true
  }
}

export { Route }